import React from 'react';
import {Table} from 'antd';
import {MONTHLY_ORDER_STATUS, MONTHLY_ORDER_STATUS_COLOR} from '../dictionary';
import moment from 'moment';
import Tag from '../Widgets/Tag';
import Anchor from '../Widgets/Anchor';
const appConfig = require('../data.json');

const MonthlyOrderTable = ({
  records,
  loading = false,
  onPaging = () => {},
  pagination = false,
}) => {
  const columns = [
    {
      title: '對帳單編號',
      key: 'id',
      render: (_, instance) => (
        <Anchor to={`/monthly/?id=${instance.id}`}>#{instance.id}</Anchor>
      ),
    },
    {
      title: '顧客姓名',
      key: 'id',
      dataIndex: 'buyer_detail',
      render: (buyer) => (
        <div>
          <div>{buyer?.name}</div>
          <div style={{color: '#aaa'}}>{buyer?.ent_name}</div>
          <div style={{color: '#aaa'}}>{buyer?.gui_number}</div>
        </div>
      ),
    },
    {
      title: '繳費狀態',
      key: 'payment_status',
      dataIndex: 'payment_status',
      render: (value) => (
        <Tag color={MONTHLY_ORDER_STATUS_COLOR[value]}>
          {MONTHLY_ORDER_STATUS[value]}
        </Tag>
      ),
    },
    {
      title: '結算區間',
      key: 'period_from',
      render: (_, instance) =>
        (instance.period_from || '-') + ' ～ ' + (instance.period_to || '-'),
    },
    {
      title: '當期訂單數',
      key: 'order_quantity',
      render: (_, instance) => `${instance.order_count || '-'}筆`,
    },

    {
      title: '當期消費總金額',
      key: 'amount',
      dataIndex: 'amount',
    },
    {
      title: '建立時間',
      render: (_, instance) => (
        <div>
          {' '}
          {instance.created.slice(0, 10)} {instance.created.slice(11, 16)}
        </div>
      ),
    },
    {
      title: '繳費截止日',
      key: 'pay_deadline',
      dataIndex: 'pay_deadline',
      render: (value) => moment(value).format('YYYY-MM-DD HH:mm:ss'),
    },
  ];

  return (
    <Table
      loading={loading}
      columns={columns}
      dataSource={records}
      rowKey={(item) => item.id}
      pagination={pagination}
      onChange={onPaging}
    />
  );
};

export default MonthlyOrderTable;
