import React from 'react';
import {Table} from 'antd';
import Button from '../Widgets/Button';
import Tag from '../Widgets/Tag';
import {MONTHLY_TYPE} from '../dictionary';
import Anchor from '../Widgets/Anchor';
const appConfig = require('../data.json');

const TableTypes = {
  MONTHLY: 0,
  OVERDUE: 1,
  OVERDRAFT: 2,
};

const MonthlyMemberTable = ({
  members,
  loading,
  onItemClick,
  type = TableTypes.MONTHLY,
  pagination,
  onPaging,
  onMonthlySettle,
}) => {
  // 月結會員總覽
  const columns = [
    {
      title: '編號',
      render: (_, instance) => (
        <Anchor to={`/member/?id=${instance.id}`}>{`#${instance.id}`}</Anchor>
      ),
      fixed: 'left',
    },
    {
      title: '公司抬頭/統一編號',
      render: (_, instance) => {
        let value = instance.data ? JSON.parse(instance.data) : {};
        return (
          <div>
            <div>{value.ent_name || '-'}</div>
            <div>{value.gui_number || '-'}</div>
          </div>
        );
      },
    },
    {
      title: '電子信箱',
      dataIndex: 'email',
    },
    // {
    //   title: '上次結算日',
    //   key: 'monthly_date',
    //   dataIndex: 'monthly_date',
    //   render: (value) => {
    //     if (value) {
    //       let overdue = moment(value)
    //         .add(30, 'days')
    //         .isSameOrBefore(new Date().toISOString().slice(0, 10));
    //       return (
    //         <span
    //           style={{
    //             color: overdue ? appConfig.colors.error : 'initial',
    //             fontWeight: '500',
    //           }}>
    //           {moment(value).format('YYYY-MM-DD')}
    //         </span>
    //       );
    //     }
    //     return (
    //       <span style={{color: appConfig.colors.error, fontWeight: '500'}}>
    //         未產生
    //       </span>
    //     );
    //   },
    // },
    // {
    //   title: '月結週期（天）',
    //   key: 'monthly_period',
    //   dataIndex: 'monthly_period',
    // },
    {
      title: '當月訂單數',
      key: 'order_count_of_monthly',
      dataIndex: 'order_count_of_monthly',
    },
    {
      title: '當月已消費金額',
      key: 'monthly_used_quota',
      dataIndex: 'monthly_used_quota',
    },
    {
      title: '月結額度',
      key: 'monthly_quota',
      dataIndex: 'monthly_quota',
    },
    {
      title: '月結結算日',
      key: 'monthly_type',
      dataIndex: 'monthly_type',
      render: (value) => MONTHLY_TYPE[value],
    },
    {
      title: '月結繳費緩衝期(天)',
      key: 'monthly_pay_buffer',
      dataIndex: 'monthly_pay_buffer',
    },
    {
      title: '已凍結',
      key: 'is_frozen',
      dataIndex: 'is_frozen',
      render: (value) => (value ? '是' : '-'),
    },
    {
      title: '登入方式',
      render: (_, instance) => (
        <p style={{marginBottom: 0}}>
          {instance.provider === 'default' ? '信箱註冊' : instance.provider}
        </p>
      ),
    },
  ];

  // 待結算清單
  // deprecated
  const columns_overdue = [
    {
      title: '編號',
      render: (_, instance) => (
        <Anchor to={`/member/?id=${instance.id}`}>{`#${instance.id}`}</Anchor>
      ),
      fixed: 'left',
    },
    {
      title: '公司抬頭/統一編號',
      render: (_, instance) => {
        let value = instance.data ? JSON.parse(instance.data) : {};
        return (
          <div>
            <p>{value.ent_name || '-'}</p>
            <p>{value.gui_number || '-'}</p>
          </div>
        );
      },
    },
    {
      title: '當月已消費金額',
      key: 'monthly_used_quota',
      dataIndex: 'monthly_used_quota',
    },
    {
      title: '月結額度',
      key: 'monthly_quota',
      dataIndex: 'monthly_quota',
    },
    {
      title: '月結結算日',
      key: 'monthly_type',
      dataIndex: 'monthly_type',
      render: (value) => MONTHLY_TYPE[value],
    },
    {
      title: '月結繳費緩衝期(天)',
      key: 'monthly_pay_buffer',
      dataIndex: 'monthly_pay_buffer',
    },
    {
      title: '產生月結單',
      key: 'settle',
      render: (record) => (
        <Button onClick={() => onMonthlySettle(record)}>出帳結算</Button>
      ),
    },
  ];

  // 未繳費清單
  // deprecated
  const columns_overdraft = [
    {
      title: '編號',
      render: (_, instance) => (
        <Anchor to={`/member/?id=${instance.id}`}>{`#${instance.id}`}</Anchor>
      ),
      fixed: 'left',
    },
    {
      title: '公司抬頭/統一編號',
      render: (_, instance) => {
        let value = instance.data ? JSON.parse(instance.data) : {};
        return (
          <div>
            <p>{value.ent_name || '-'}</p>
            <p>{value.gui_number || '-'}</p>
          </div>
        );
      },
    },
    {
      title: '未結清狀態',
      key: 'overdraft',
      dataIndex: 'overdraft',
      render: (value) => (
        <Tag color={value ? appConfig.colors.error : ''}>
          {value ? '未結清' : '已結清'}
        </Tag>
      ),
    },
  ];

  return (
    <Table
      loading={loading}
      columns={
        type === TableTypes.MONTHLY
          ? columns
          : type === TableTypes.OVERDUE
          ? columns_overdue
          : columns_overdraft
      }
      dataSource={members}
      rowKey={(member) => member.id}
      pagination={pagination}
      onChange={onPaging}
      // scroll={{ x: 1300 }}
    />
  );
};

export default MonthlyMemberTable;

export {TableTypes};
