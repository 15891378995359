import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import styled from 'styled-components';
import MemberTable, {TableTypes} from '../../Components/MonthlyMemberTable';
import {message, Alert, Input, Select} from 'antd';
import {useOutlet} from 'reconnect.js';
const appConfig = require('../../data.json');
import {navigate} from 'gatsby';
import Block from '../../Widgets/Block';
import Button from '../../Widgets/Button';
import Hint from '../../Widgets/Hint';
import Tabs from '../../Widgets/Tabs';
import {Refresh} from '@styled-icons/boxicons-regular/Refresh';
import MonthlyOrderTable from '../../Components/MonthlyOrderTable';
import MonthlyDialog, {TYPE as DIALOG_TYPE} from '../../Dialogs/MonthlyDialog';
import {errorHandler} from '../../errors';
import {MONTHLY_ORDER_STATUS, MONTHLY_TYPE} from '../../dictionary';
import getOptions from '../../Utils/getOptions';
const qs = require('query-string');

export const UI_STATE = {
  MONTHLY_LIST: 'MONTHLY_LIST',
  OVERDUE_LIST: 'OVERDUE_LIST',
  OVERDRAFT_LIST: 'OVERDRAFT_LIST',
  MONTHLY_ORDER: 'MONTHLY_ORDER',
};

const PAGINATION_INIT = {
  current: 1,
  pageSize: 20,
};

export default function MonthlyMemberList({...props}) {
  const {location} = props;
  const {uiState: _uiState} = qs.parse(location.search);
  const [members, setMembers] = useState([]);
  const [monthlyOrders, setMonthlyOrders] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useOutlet('loading');
  const [actions] = useOutlet('actions');
  const [filters, setFilters] = useState({
    search_str: '',
    monthly_type: '',
    ordering: '-created',
    payment_status: 'all',
    uiState: _uiState || UI_STATE.MONTHLY_LIST,
    ...PAGINATION_INIT,
  });
  const [selected, setSelected] = useState();
  const changeFilters = (obj) => {
    setFilters((prev) => ({...prev, ...PAGINATION_INIT, ...obj}));
  };

  const getMembers = useCallback(async () => {
    if (filters.uiState === UI_STATE.MONTHLY_LIST) {
      let params = {
        user_type: 'monthly',
        // user_type: "normal,vip", //multi query
        ...(filters.monthly_type && {monthly_type: filters.monthly_type}),
        search: filters.search_str,
        offset: (filters.current - 1) * filters.pageSize,
        limit: filters.pageSize,
      };

      setLoading(true);

      try {
        let {results, count} = await actions.getMembers(params);
        setMembers(results);
        setTotal(count);
      } catch (err) {
        console.log('err', err);
        message.error('發生錯誤');
      }
      setLoading(false);
    }
  }, [filters, actions.getMembers]);

  const getOverdueMembers = useCallback(async () => {
    if (filters.uiState === UI_STATE.OVERDUE_LIST) {
      setLoading(true);
      let params = {
        // user_type: 'monthly',
        // user_type: "normal,vip", //multi query
        // search: filters.search_str,
        from_date: new Date().toISOString().slice(0, 10),
        offset: (filters.current - 1) * filters.pageSize,
        limit: filters.pageSize,
        ...(filters.uiState === UI_STATE.OVERDRAFT_LIST && {overdraft: true}),
      };

      try {
        let {results, count} = await actions.overdueMonthlyMember(params);
        setMembers(results);
        setTotal(count);
      } catch (err) {
        console.log('err', err);
        message.error('發生錯誤');
      }
      setLoading(false);
    }
  }, [filters, actions.overdueMonthlyMember]);

  const getMonthlyOrders = useCallback(async () => {
    if (filters.uiState === UI_STATE.MONTHLY_ORDER) {
      setLoading(true);
      try {
        let resp = await actions.getMonthlyOrders({
          ...(filters.payment_status !== 'all' && {
            payment_status: filters.payment_status,
          }),
          ordering: filters.ordering,
          offset: (filters.current - 1) * filters.pageSize,
          limit: filters.pageSize,
        });
        setMonthlyOrders(resp.results);
        setTotal(resp.count);
      } catch (err) {
        errorHandler(err);
      }
      setLoading(false);
    }
  }, [filters]);

  const refresh = useCallback(() => {
    getOverdueMembers();
    getMonthlyOrders();
    getMembers();
  }, [getMembers, getOverdueMembers, getMonthlyOrders]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  useEffect(() => {
    if (_uiState) {
      changeFilters({
        uiState: _uiState,
        ...PAGINATION_INIT,
      });
    }
  }, [_uiState]);

  return (
    <Wrapper>
      <Block style={{marginBottom: 20}}>
        {filters.uiState === UI_STATE.MONTHLY_LIST && (
          <div>
            <div className="row">
              <h4>月結結算日</h4>
              <Select
                value={filters.monthly_type}
                onChange={(value) => changeFilters({monthly_type: value})}
                style={{marginRight: 40}}>
                <Select.Option value="">不限</Select.Option>
                {Object.keys(MONTHLY_TYPE).map((key, idx) => (
                  <Select.Option key={idx} value={key}>
                    {MONTHLY_TYPE[key]}
                  </Select.Option>
                ))}
              </Select>
              <h4>搜尋</h4>
              <Input
                value={filters.search_str}
                onChange={(e) => {
                  let value = e.target.value;
                  changeFilters({search_str: value});
                }}
                placeholder="姓名、電話、Email"
              />
            </div>
          </div>
        )}
        {filters.uiState === UI_STATE.OVERDUE_LIST && (
          <div>
            <Hint type="info" style={{marginBottom: 8}}>
              注意：
            </Hint>
            <p>
              尚有 <span style={{color: appConfig.colors.error}}>{total}</span>{' '}
              名會員未結算
            </p>
            <p>
              按下結算後，系統將會取得該會員所有的未結算訂單，納入此次月結訂單
            </p>
            <p>上次結算日 ～ 點選結算的時刻 = 月結訂單的區間</p>
          </div>
        )}

        {filters.uiState === UI_STATE.OVERDRAFT_LIST && (
          <div>
            <Hint type="info" style={{marginBottom: 8}}>
              注意：
            </Hint>
            <p>此清單僅列出已結算、並已產生月結單之會員。</p>
            <p>
              請手動檢查是否自結算日超過7日未付款之會員，如未付款請將會員凍結，防止會員再次下單。
            </p>
          </div>
        )}

        {filters.uiState === UI_STATE.MONTHLY_ORDER && (
          <div>
            <div className="row">
              <h4>繳費狀態</h4>
              <Select
                value={filters.payment_status}
                onChange={(value) => changeFilters({payment_status: value})}
                style={{marginRight: 40}}>
                {getOptions(MONTHLY_ORDER_STATUS).map((x, idx) => (
                  <Select.Option key={idx} value={x.value}>
                    {x.label}
                  </Select.Option>
                ))}
              </Select>
              <h4>排序方式</h4>
              <Select
                value={filters.ordering}
                onChange={(value) => changeFilters({ordering: value})}>
                <Select.Option value="-created">最新到舊</Select.Option>
                <Select.Option value="+created">最舊到新</Select.Option>
              </Select>
            </div>
          </div>
        )}

        <div className="divider" />

        <div className="row" style={{margin: 0}}>
          <div style={{fontWeight: '500', marginRight: 5}}>共</div>
          <div style={{fontWeight: '500', marginRight: 5}}>{total}</div>
          <div style={{fontWeight: '500', marginRight: 5}}>筆</div>

          <div style={{flex: 1}} />

          <Button
            type="primary"
            onClick={refresh}
            icon={<Refresh color="#fff" size={20} style={{marginRight: 5}} />}>
            刷新
          </Button>
        </div>
      </Block>

      <Block>
        <div>
          <Tabs
            options={[
              {value: 'MONTHLY_LIST', label: '月結會員總覽'},
              // {value: 'OVERDUE_LIST', label: '待出帳會員'},
              // { value: 'OVERDRAFT_LIST', label: '待繳費會員'},
              {value: 'MONTHLY_ORDER', label: '月結對帳單'},
            ]}
            value={filters.uiState}
            onChange={(value) => {
              navigate(`/monthly_member/?uiState=${value}`);
            }}
            style={{marginBottom: 10}}
          />
          {filters.uiState === UI_STATE.MONTHLY_ORDER ? (
            <MonthlyOrderTable
              records={monthlyOrders}
              pagination={{
                pageSize: filters.pageSize,
                current: filters.current,
                total,
              }}
              onPaging={(pagination) =>
                changeFilters({
                  pageSize: pagination.pageSize,
                  current: pagination.current,
                })
              }
            />
          ) : (
            <MemberTable
              type={
                {
                  [UI_STATE.MONTHLY_LIST]: TableTypes.MONTHLY,
                  [UI_STATE.OVERDUE_LIST]: TableTypes.OVERDUE,
                  [UI_STATE.OVERDRAFT_LIST]: TableTypes.OVERDRAFT,
                }[filters.uiState]
              }
              loading={loading}
              members={members}
              pagination={{
                pageSize: filters.pageSize,
                current: filters.current,
                total,
              }}
              onPaging={(pagination) =>
                changeFilters({
                  pageSize: pagination.pageSize,
                  current: pagination.current,
                })
              }
              onMonthlySettle={(record) => setSelected(record)}
            />
          )}
        </div>
      </Block>

      <MonthlyDialog
        type={selected ? DIALOG_TYPE.SETTLE : false}
        onClose={() => setSelected(null)}
        onUpdate={refresh}
        buyer={selected}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: #f2f2f2;
  padding: 20px;
`;
