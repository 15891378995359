import React, {Fragment, useCallback, useEffect, useState} from 'react';
import {Close} from '@styled-icons/material/Close';
import {Modal, message, Spin, Input, Select} from 'antd';
import {useOutlet} from 'reconnect.js';
import {errorHandler} from '../errors';
import {MONTHLY_ORDER_STATUS, MONTHLY_TYPE} from '../dictionary';
import Button from '../Widgets/Button';
import Block from '../Widgets/Block';
import Row from '../Widgets/Row';
import Divider from '../Widgets/Divider';
import Hint from '../Widgets/Hint';

export const TYPE = {
  EDIT: '編輯月結單',
  SETTLE: '出帳結算',
};

export default function MonthlyDailog(props) {
  const {record, onClose, type, onUpdate, buyer} = props;
  const [loading, setLoading] = useState(false);
  const [actions] = useOutlet('actions');
  const [values, setValues] = useState(record);
  const [orders, setOrders] = useState([]);
  const [lastMonthlyOrder, setLastMonthlyOrder] = useState();

  useEffect(() => {
    setValues(props.record);
  }, [props.record]);

  function changeValues(obj) {
    setValues((prev) => ({...prev, ...obj}));
  }

  const edit = useCallback(async (values) => {
    setLoading(true);
    try {
      await actions.editMonthlyOrder({
        id: values.id,
        note: values.note,
        payment_status: values.payment_status,
      });
      message.success('編輯成功');
      onUpdate();
      onClose();
    } catch (err) {
      errorHandler(err);
      message.error('編輯失敗');
    }
    setLoading(false);
  }, []);

  const settle = useCallback(
    async (member_id) => {
      if (member_id) {
        setLoading(true);
        try {
          await actions.settleMonthlyOrder({
            buyer: member_id,
          });
          message.success('出帳結算成功！詳情請看月結對帳單');
          onUpdate();
          onClose();
        } catch (ex) {
          message.warning('結算失敗');
          console.warn(ex);
        }
        setLoading(false);
      }
    },
    [actions.settleMonthlyOrder],
  );

  useEffect(() => {
    async function getOrders() {
      let resp = await actions.getOrders({
        buyer: buyer.id,
        payment_type: 'monthly',
        monthly_unbilled: true,
        // monthly_order: null, // backend has not apply
        no_page: true,
      });
      setOrders(resp);
    }
    async function getLastMonthly() {
      let {results} = await actions.getMonthlyOrders({
        buyer_id: buyer.id,
        ordering: '-created',
        offset: 0,
        limit: 1,
      });
      if (results.length > 0) {
        setLastMonthlyOrder(results[0]);
      }
    }
    if (type === TYPE.SETTLE) {
      (async () => {
        setLoading(true);
        try {
          await Promise.all([getOrders(), getLastMonthly()]);
        } catch (err) {
          errorHandler(err);
        }
        setLoading(false);
      })();
    }
  }, [type]);

  return (
    <Modal
      visible={!!type}
      title={type}
      footer={
        <Row
          style={{
            justifyContent: 'flex-end',
          }}>
          <Button style={{marginRight: 10}} onClick={onClose}>
            取消
          </Button>
          <Button
            loading={loading}
            type="primary"
            onClick={() => {
              if (type === TYPE.EDIT) {
                edit(values);
              } else if (type === TYPE.SETTLE) {
                settle(buyer.id);
              }
            }}>
            確認
          </Button>
        </Row>
      }
      closeIcon={
        <Close style={{position: 'absolute', right: 20, top: 20}} size={20} />
      }
      onCancel={onClose}
      width="80%">
      <Block>
        {type === TYPE.EDIT ? (
          <div>
            <div className="row">
              <h4>付款狀態</h4>
              <Select
                value={values.payment_status}
                onChange={(value) => {
                  changeValues({payment_status: value});
                }}>
                {Object.keys(MONTHLY_ORDER_STATUS).map((key) => (
                  <Select.Option value={key} key={key}>
                    {MONTHLY_ORDER_STATUS[key]}
                  </Select.Option>
                ))}
              </Select>
            </div>

            <div className="row">
              <h4>後台人員備註</h4>
              <Input.TextArea
                value={values.note}
                onChange={(e) => {
                  changeValues({note: e.target.value});
                }}
              />
            </div>
          </div>
        ) : type === TYPE.SETTLE ? (
          <div>
            <div className="row">
              <h4>月結額度</h4>
              <div>{buyer.monthly_quota} 元</div>
            </div>
            <div className="row">
              <h4>月結結算日</h4>
              <div>{MONTHLY_TYPE[buyer.monthly_type] || '--'}</div>
            </div>
            <div className="row">
              <h4>月結繳費緩衝期</h4>
              <div>{buyer.monthly_pay_buffer} 天</div>
            </div>
            <div className="row">
              <h4>上次月結對帳單</h4>
              <div># {lastMonthlyOrder?.id || '-'}</div>
            </div>
            <div className="row">
              <h4>上次結算區間</h4>
              <div>
                {lastMonthlyOrder?.period_from} ~ {lastMonthlyOrder?.period_to}
              </div>
            </div>
            <Divider>當期結算內容</Divider>
            <div className="row">
              <h4>當期結算區間</h4>
              <div>
                {lastMonthlyOrder?.period_to} ~{' '}
                {new Date().toLocaleDateString('sv')}
              </div>
            </div>
            <div className="row">
              <h4>當期訂單數</h4>
              <div>
                {orders.filter((order) => order.voided === false).length}筆
              </div>
            </div>
            <div className="row">
              <h4>當期消費總金額</h4>
              <div>{buyer.monthly_used_quota}元</div>
            </div>
            <div className="row">
              <Hint type="info">確定要結算月結單？</Hint>
            </div>
          </div>
        ) : null}
      </Block>
    </Modal>
  );
}
