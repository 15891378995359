function getOptions(obj) {
  if (typeof obj === 'object' && !Array.isArray(obj) && obj !== null) {
    result = Object.keys(obj).map((key) => ({
      label: obj[key],
      value: key,
    }));
    if (result[0]?.value !== 'all') {
      result.unshift({label: '全部', value: 'all'});
    }
    return result;
  }
  throw new Error('Error: getOptions obj is not object');
}

module.exports = getOptions;
